import { EventEmitter } from 'events'
import { isTokenExpired } from './../helpers/JwtHelper'
import auth0 from 'auth0-js'

export default class AuthService extends EventEmitter {
    constructor(clientId, domain) {
        super();

        this.auth0 = new auth0.WebAuth({
            clientID: clientId,
            domain: domain,
            responseType: 'token id_token'
        })

        this.state = {
            auth0: this.auth0
        };
    }

    login(username, password, callback) {
        this.state.auth0.login({
            realm: 'Zanzar-PWA-PontoApoio',
            username: username,
            password: password,
            redirectUri: `${window.location.origin}/callback`
        }, callback)
    }

    parseHash(hash) {
        this.state.auth0.parseHash({ hash }, (err, authResult) => {
            if (authResult && authResult.accessToken && authResult.idToken) {
                this.setToken(authResult.accessToken, authResult.idToken)

                this.auth0.client.userInfo(authResult.accessToken, (error, profile) => {
                    if (error) {
                        console.log('Error loading the Profile', error)
                    } else {
                        this.setProfile(profile)
                    }
                    window.location = '/dashboard';
                })
            } else if (authResult && authResult.error) {
                alert('Error: ' + authResult.error)
            }
        })
    }

    loggedIn() {
        // Checks if there is a saved token and it's still valid
        const token = this.getToken()
        return !!token && !isTokenExpired(token)
    }

    setToken(accessToken, idToken) {
        // Saves user access token and ID token into local storage
        // localStorage.setItem('access_token', accessToken)
        localStorage.setItem('id_token', idToken)
        localStorage.getItem('ponto_apoio_logado', accessToken)
        this.getUserInfo(accessToken);
    }

    setProfile(profile) {
        // Saves profile data to localStorage
        localStorage.setItem('profile', JSON.stringify(profile))
        // Triggers profile_updated event to update the UI
        this.emit('profile_updated', profile)
    }

    getProfile() {
        // Retrieves the profile data from localStorage
        const profile = localStorage.getItem('profile')
        return profile ? JSON.parse(localStorage.profile) : {}
    }

    getToken() {
        // Retrieves the user token from localStorage
        return localStorage.getItem('id_token')
    }

    getUserInfo(accessToken) {
        this.state.auth0.client.userInfo(accessToken, (error, profile) => {
            if (error) {
                console.log('Error loading the Profile', error)
            } else {
                this.setProfile(profile)
            }
        })
    }

    logout() {
        // Clear user token and profile data from localStorage
        localStorage.removeItem('id_token');
        localStorage.removeItem('profile');
        localStorage.removeItem('ponto_apoio_logado');

        if (window.location.pathname !== '/login')
            window.location = '/login';
    }
}
