import React, { Component } from 'react';
const formatDate = require('date-format');

export default class Cabecalho extends Component {
    constructor(props){
        super(props);

        this.state = {
            error: '',
            nomesMeses:  ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho",
                          "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
            dataEscolhida: new Date(),
            pontos: [],
            cd_motorista: null
        }
    }

    componentWillMount = async () => {
        let agendamento = localStorage.getItem('agendamento');

        if (typeof agendamento !== 'undefined' && agendamento !== null){
            this.setState({ dataEscolhida: new Date(agendamento) });
            this.props.alterarDataParent(new Date(agendamento));
        }
    }

    _alterarDia = (operador) => {
        let agendamento = new Date(this.state.dataEscolhida);
        
        if (operador === '+')
            agendamento.setDate(agendamento.getDate() + 1);
        else if (operador === '-')
            agendamento.setDate(agendamento.getDate() - 1);

        localStorage.setItem('agendamento', agendamento);

        this.setState({ dataEscolhida: agendamento });
        this.props.alterarDataParent(agendamento);
    }

    render() {
        return (
            <div>
                <div className="space-16"></div>
                <div className="title title-32 margin-22">Agendamentos</div>
                <div className="space-28"></div>
    
                <div className="cf-texto cf-title margin-27">
                    <div className="float-left" onClick={ () => this._alterarDia('-') }>&lt;&nbsp;&nbsp;&nbsp;</div>
                    <div className="float-left"> 
                        { formatDate('dd de  ', this.state.dataEscolhida) }
                        { this.state.nomesMeses[this.state.dataEscolhida.getMonth()] }
                    </div>
                    <div className="float-left" onClick={ () => this._alterarDia('+') }>&nbsp;&nbsp;&nbsp;&gt;</div>
                </div>
    
                <div className="space-23"></div>
    
                <img src={require('../../../resources/imgs/svg/noun-wave-green.svg')}  className="noun-wave" alt="" />
    
                <div className="space-21"></div>
            </div>
        );
    }
}
