import React, { Component } from 'react';
import auth from '../../auth/initAuth'

export default class Callback extends Component {

    constructor(props){
        super();
    }

    componentDidMount() {
        auth.parseHash(this.props.location.hash);      
    }

    render() {
        return <h1>&nbsp;</h1>
      }
}