import React, { Component } from 'react';
import TopoSemMenu from '../../components/TopoSemMenu';
import FormEsqueciSenha from './components/FormEsqueciSenha';

export default class EsqueciSenha extends Component {
    render() {
        return (
            <div>
                <TopoSemMenu/>
                <FormEsqueciSenha/>
            </div>
        );
    }
}