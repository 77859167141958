import React, { Component } from 'react';
import { obterServicosEmAberto } from '../../../actions/AgendamentoActions';
const formatDate = require('date-format');

export default class ServicosEmAberto extends Component {
    constructor(props) {
        super(props);

        this.state = {
            error: '',
            dataEscolhida: new Date(),
            agendamentos: []
        }
    }

    componentWillMount = async () => {
        let data = formatDate('ddMMyyyy', this.state.dataEscolhida);

        obterServicosEmAberto(localStorage.getItem('ponto_apoio'), data)
            .then(agendamentos => {
                this.setState({ agendamentos: agendamentos });
            });
    }

    componentWillReceiveProps = (props) => {
        let data = formatDate('ddMMyyyy', props.dataEscolhida);

        obterServicosEmAberto(localStorage.getItem('ponto_apoio'), data)
            .then(agendamentos => {
                this.setState({ agendamentos: agendamentos });
            });
    }

    _abrirDetalhe = function (agendamento_detalhe) {
        localStorage.setItem('zanzar_agendamento_detalhe', JSON.stringify(agendamento_detalhe));

        window.location = '/agendamento-detalhe';
    }

    render() {
        return (
            <div>
                <div className="cf-texto cf-title margin-22">
                    { this.state.agendamentos ? this.state.agendamentos.length : 0 } <span className="title-16">Instalações em aberto</span>
                </div>
                {
                    this.state.agendamentos ? this.state.agendamentos.map((agendamento, k) => {
                        return <div key={k}>
                            <div className="space-16"></div>
                            <div className="box-radius margin-22">
                                <div className="margin-20 cf-texto title-16 title-bold title-gray">Motorista</div>
                                <div className="space-8"></div>
                                <div className="margin-30 cf-texto title-16 title-bold title-gray">
                                    { agendamento.motorista_nome }
                                    <div className="space-8"></div>
                                    { agendamento.motorista_veiculo.fabricante + ' / ' + agendamento.motorista_veiculo.modelo + ' / ' + agendamento.motorista_veiculo.ano }
                                </div>

                                <button className="bt-box-radius uppercase" onClick={() => this._abrirDetalhe(agendamento)}>INICIAR { agendamento.tipo_servico_descricao }</button>
                            </div>
                        </div>
                    }) : <div></div>
                }
            </div>
        );
    }
}
