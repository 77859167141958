import axios from "axios";

const config = {
  headers: { Authorization: "Bearer " + localStorage.getItem("id_token") },
};

const urlAPI = process.env.REACT_APP_URL_API_BASE;

export const listarMotivos = async () => {
  let response = await axios.get(
    urlAPI + "/servico-motivo/listar-ativos/",
    config
  );
  return response.data;
};
