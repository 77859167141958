import axios from "axios";

const config = {
  headers: { Authorization: "Bearer " + localStorage.getItem("id_token") },
};

const urlAPI = process.env.REACT_APP_URL_API_BASE;

export const obterUltimoLoteRecebimento = async (cd_ponto_apoio) => {
  let response = await axios.get(
    urlAPI + "/distribuicao-lote/utlimo-lote-recbimento/" + cd_ponto_apoio,
    config
  );
  return response.data;
};

export const obterProximoLoteRecebimento = async (cd_ponto_apoio) => {
  let response = await axios.get(
    urlAPI + "/distribuicao-lote/proximo-lote/" + cd_ponto_apoio,
    config
  );
  return response.data;
};

export const obterProximosLotesRecebimento = async (cd_ponto_apoio) => {
  let response = await axios.get(
    urlAPI + "/distribuicao-lote/listar-a-receber/" + cd_ponto_apoio,
    config
  );
  return response.data;
};

export const obterEquipamentosLote = async (cd_lote) => {
  let response = await axios.get(
    urlAPI + "/distribuicao-lote-equipamento/equipamentos/" + cd_lote,
    config
  );
  return response.data;
};

export const confirmarRecebimentoEquipamentos = async (
  cd_lote,
  equipamentos
) => {
  return axios.patch(
    urlAPI + "/distribuicao-lote/confirmar-recebimento/" + cd_lote,
    equipamentos,
    config
  );
};

export const obterEstoque = async (cd_ponto_apoio) => {
  let response = await axios.get(
    urlAPI + "/equipamento/listar-estoque/" + cd_ponto_apoio,
    config
  );
  return response.data;
};
