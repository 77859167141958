import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import Cabecalho from './Cabecalho';
import ServicosEmAberto from './ServicosEmAberto';
import ServicosRalizados from './ServicosRalizados';

class Agendamentos extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dataEscolhida: null
        };    
    }

    alterarData = (dataEscolhida) => {
        this.setState({ dataEscolhida: dataEscolhida });
    }

    render() {
        return (
            <div className="page-content-normal header-clear">
                <Cabecalho alterarDataParent={ this.alterarData } />
                <ServicosEmAberto dataEscolhida={ this.state.dataEscolhida } />

                <div className="space-21"></div>

                <ServicosRalizados dataEscolhida={ this.state.dataEscolhida } />

                <div className="space-71"></div>
            </div>
        );
    }
}

export default withRouter(Agendamentos);
