import axios from "axios";

const config = {
  headers: { Authorization: "Bearer " + localStorage.getItem("id_token") },
};

const urlAPI = process.env.REACT_APP_URL_API_BASE;

export const obterDatasDisponiveis = async (cd_motorista, reagendar) => {
  let response = await axios.get(
    urlAPI +
      "/agendamento/obter-datas-disponiveis/" +
      cd_motorista +
      "/" +
      reagendar,
    config
  );

  return response.data.datas;
};

export const obterPontosDisponiveis = async (cd_motorista, reagendar) => {
  let response = await axios.get(
    urlAPI +
      "/agendamento/obter-pontos-disponiveis/" +
      cd_motorista +
      "/" +
      reagendar,
    config
  );

  return response.data.pontos;
};

export const obterAgendamento = async (cd_motorista) => {
  let response = await axios.get(
    urlAPI + "/agendamento/listar-agendado/" + cd_motorista,
    config
  );

  return response.data;
};

export const obterServicosEmAberto = async (cd_ponto_apoio, data) => {
  let response = await axios.get(
    urlAPI +
      "/agendamento/listar-agendado-por-ponto/" +
      cd_ponto_apoio +
      "?data=" +
      data,
    config
  );

  return response.data;
};

export const obterServicosRealizados = async (cd_ponto_apoio, data) => {
  let response = await axios.get(
    urlAPI +
      "/agendamento/listar-finalizado-por-ponto/" +
      cd_ponto_apoio +
      "?data=" +
      data,
    config
  );

  return response.data;
};

export const obterServicosEmAbertoMes = async (cd_ponto_apoio, data) => {
  let response = await axios.get(
    urlAPI +
      "/agendamento/listar-em-aberto-por-mes/" +
      cd_ponto_apoio +
      "?data=" +
      data,
    config
  );

  return response.data;
};

export const obterServicosRealizadosMes = async (cd_ponto_apoio, data) => {
  let response = await axios.get(
    urlAPI +
      "/agendamento/listar-finalizado-por-mes/" +
      cd_ponto_apoio +
      "?data=" +
      data,
    config
  );

  return response.data;
};

export const obterHistoricoMes = async (cd_ponto_apoio, mesAno) => {
  let response = await axios.get(
    urlAPI +
      "/agendamento/listar-agendamento-historico/" +
      cd_ponto_apoio +
      "?mesAno=" +
      mesAno,
    config
  );

  return response.data;
};

export const confirmarInstalacao = async (
  cd_agendamento,
  cd_tv_zanzar,
  cd_ponto_apoio
) => {
  let response = await axios.patch(
    urlAPI + "/agendamento/confirmar-instalacao/" + cd_agendamento,
    {
      cd_tv_zanzar: cd_tv_zanzar,
      cd_ponto_apoio: cd_ponto_apoio,
    },
    config
  );

  return response.data;
};

export const comunicarNaoInstalacao = async (
  cd_agendamento,
  cd_motivo_servico,
  observacao
) => {
  let response = await axios.patch(
    urlAPI + "/agendamento/comunicar-nao-instalacao/" + cd_agendamento,
    {
      cd_motivo_servico: cd_motivo_servico,
      observacao: observacao,
    },
    config
  );

  return response.data;
};
