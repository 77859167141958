import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import $ from 'jquery';
import { obterHistoricoMes  } from '../../../actions/AgendamentoActions';

const formatDate = require('date-format');

class Historico extends Component {
    constructor(props){
        super(props);

        this.state = {
            error: '',     
            dataEscolhida: null,
            historico: [],
            cd_motorista: null
        };
    }

    componentDidMount = () => {
        this.setState({ dataEscolhida: new Date() });

        $('.loader-main').removeClass('loader-inactive');

        const data = formatDate('MMyyyy', this.state.dataEscolhida)

        obterHistoricoMes(localStorage.getItem('ponto_apoio'), data)
            .then(historico => {
                this.setState({ historico: historico });

                $('.loader-main').addClass('loader-inactive');
            })
            .catch(error => {
                console.log(error)

                $('.loader-main').addClass('loader-inactive');
            })
    }

    componentDidUpdate = async (prevProps) => {
        if (this.props.dataEscolhida !== prevProps.dataEscolhida) {
            $('.loader-main').removeClass('loader-inactive');

            const data = formatDate('MMyyyy', this.props.dataEscolhida)

            let historico = await obterHistoricoMes(localStorage.getItem('ponto_apoio'), data);

            this.setState({ historico: historico });

            $('.loader-main').addClass('loader-inactive');
        }
    }

    _listar() {
        let ultimaData = '';
        let dataAtual = '';
        let dataExibir = '';

        const listItems = this.state.historico.length ? this.state.historico.map((item, k) => {
            dataAtual =  formatDate('dd/MM', new Date(item.dt_agendada));

            if (ultimaData !== dataAtual) {
                dataExibir = dataAtual;
            } else {
                dataExibir = '';
            }

            ultimaData = dataAtual

            return  <div key={ k }>
                        <div className="row clear">                
                            <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2 padding-0">
                                <div className="space-12"></div>
                                <div className="cf-texto margin-0">
                                    { dataExibir }
                                </div>
                            </div>
                            <div className="col-lg-10 col-md-10 col-sm-10 col-xs-10 line-left">
                                <div className="space-12"></div>
                                <div className="row clear margin-0">
                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 cf-texto title-16">{ item.servico_desc }</div>
                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 cf-texto title-16">{ item.motorista_nome }</div>
                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 cf-texto title-16">{ item.motorista_veiculo.fabricante + ' / ' + item.motorista_veiculo.modelo + ' / ' + item.motorista_veiculo.ano }</div>
                                </div>
                            </div>
                        </div>                                
                    </div>
        }) : <div></div>

        return (
            listItems
        );
    }

    render() {
        return (
            <div className="margin-32">         
                <div className="cf-texto cf-sub-text">Registros</div>

                <div className="space-28"></div>
                <div className="row clear line-bottom">       
                    <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2 padding-0">
                        <div className="cf-texto cf-sub-text margin-0 space-7">Dia</div>
                    </div>
                    <div className="col-lg-10 col-md-10 col-sm-10 col-xs-10 line-left">
                        <div className="cf-texto cf-sub-text margin-0 space-7">Descrição</div>
                    </div>
                </div>

                { this._listar() }

                <div className="space-71"></div>
            </div>
        );
    }
}

export default withRouter(Historico);
