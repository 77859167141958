import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import $ from 'jquery';
import * as Logger from '../../../helpers/Logger';
import { obterServicosEmAbertoMes, obterServicosRealizadosMes } from '../../../actions/AgendamentoActions';

const formatDate = require('date-format');

class Instalacoes extends Component {
    constructor(props) {
        super(props);

        this.dataEscolhida = this.props.dataEscolhida;

        this.state = {
            error: '',
            dashboard: [],
            servicoes_pendentes: [],
            servicos_realizados: [],
            cd_ponto_apoio: null
        };
    }

    componentDidMount = () => {
        this.carregarDados(this.dataEscolhida);
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.dataEscolhida !== prevProps.dataEscolhida) {
            this.carregarDados(this.props.dataEscolhida);
        }
    }

    carregarDados = async (dataEscolhida) => {
        $('.loader-main').removeClass('loader-inactive');

        let data = formatDate('MMyyyy', dataEscolhida)

        Logger.info('APOIO : DASHBOARD - Instalacoes : dataEscolhida : ' + dataEscolhida);
        Logger.info('APOIO : DASHBOARD - Instalacoes : data : ' + data);

        try {
            let servicoesPendentes = await obterServicosEmAbertoMes(localStorage.getItem('ponto_apoio'), data);
            let servicosRealizados = await obterServicosRealizadosMes(localStorage.getItem('ponto_apoio'), data);

            Logger.info('APOIO : DASHBOARD - Instalacoes : servicoesPendentes : ' + (servicoesPendentes ? servicoesPendentes.length : 0));
            Logger.info('APOIO : DASHBOARD - Instalacoes : servicoesPendentes : ' + JSON.stringify(servicoesPendentes));

            Logger.info('APOIO : DASHBOARD - Instalacoes : servicosRealizados : ' + (servicosRealizados ? servicosRealizados.length : 0));
            Logger.info('APOIO : DASHBOARD - Instalacoes : servicosRealizados : ' + JSON.stringify(servicosRealizados));

            this.setState({ servicoes_pendentes: servicoesPendentes, servicos_realizados: servicosRealizados });

            $('.loader-main').addClass('loader-inactive');
        } catch (error) {
            Logger.error('APOIO : DASHBOARD - Instalacoes : ERRO 1 : ' + error.stack);
            Logger.error('APOIO : DASHBOARD - Instalacoes : ERRO 2 : ' + JSON.stringify(error));

            $('.loader-main').addClass('loader-inactive');
        }

        Logger.info('APOIO : DASHBOARD - Instalacoes : FIM');
    }

    render() {
        return (
            <div>
                <div className="row clear space-25">
                    <div className="cf-texto cf-title margin-32">Instalações</div>

                    <div className="space-28"></div>
                    <div onClick={ () => { this.props.history.push('/agendamentos') } }>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                            <div className="cf-texto cf-sub-title margin-20">Realizadas</div>
                            <div className="space-8"></div>
                            <div className="title title-32 text-destaque2">
                                { this.state.servicos_realizados ? this.state.servicos_realizados.length : 0 }
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 Line-3">
                            <div className="cf-texto cf-sub-title margin-14">Pendentes</div>
                            <div className="space-8"></div>
                            <div className="title title-32 text-destaque2">
                                { this.state.servicoes_pendentes ? this.state.servicoes_pendentes.length : 0 }
                            </div>
                        </div>
                    </div>
                </div>

                <div className="space-22"></div>
                <div className="row clear">
                    <Link to={'/dashboard-historico'}>
                        <button className="cf-bt-mini margin-30 space-16">VER HISTÓRICO</button>
                    </Link>

                    <div className="space-28"></div>
                </div>
            </div>
        );
    }
}

export default withRouter(Instalacoes);
