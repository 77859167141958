import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

class Cabecalho extends Component {
    constructor(props){
        super(props);

        this.state = {
            error: '',
            nomesMeses: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
            dataEscolhida: new Date(),
            pontos: [],
            cd_motorista: null
        };
    }

    _alterarMes = (operador) => {
        const dashboard = new Date(this.state.dataEscolhida);
        
        if (operador === '+') {
            dashboard.setMonth(dashboard.getMonth() + 1);
        } else if (operador === '-') {
            dashboard.setMonth(dashboard.getMonth() - 1);
        }

        this.setState({ dataEscolhida: dashboard });

        this.props.alterarDataParent(dashboard);
    }

    render() {
        return (
            <div className="header-clear">
                <div className="space-8"></div>    
                <div className="title title-32">Dashboard</div>
                <div className="space-23"></div>    

                <div className="cf-texto cf-title margin-20">
                    <div className="float-left" onClick={ () => this._alterarMes('-') }>&lt;&nbsp;&nbsp;&nbsp;</div>
                    <div className="float-left">
                        { this.state.nomesMeses[this.state.dataEscolhida.getMonth()] }
                    </div>
                    <div className="float-left" onClick={ () => this._alterarMes('+') }>&nbsp;&nbsp;&nbsp;&gt;</div>
                </div>

                <img src={require('../../../resources/imgs/svg/noun-wave-green.svg')} className="noun-wave" alt="" />
            </div>
        );
    }
}

export default withRouter(Cabecalho);
