import React, { Component } from 'react';
import TopoSemMenu from '../../components/TopoSemMenu';
import AgendamentoDetalhe from './components/AgendamentoDetalhe';

export default class ReceberEquipamentos extends Component {
    render() {
        return (
            <div>
                <TopoSemMenu/>
                <AgendamentoDetalhe/>
            </div>
        );
    }
}