import winston from "winston";
import { Loggly } from "winston-loggly-bulk";
import * as ClientUtil from "./ClientUtil";

winston.add(
  new Loggly({
    token: process.env.REACT_APP_LOGGLY_TOKEN,
    subdomain: process.env.REACT_APP_LOGGLY_SUB_DOMAIN,
    tags: process.env.REACT_APP_LOGGLY_TAGS,
    json: true,
  })
);

export const info = (msg) => {
  winston.info(msg, { tags: ClientUtil.getClientKey() });
};

export const error = (msg) => {
  winston.error(msg, { tags: ClientUtil.getClientKey() });
};

export const warn = (msg) => {
  winston.warn(msg, { tags: ClientUtil.getClientKey() });
};

export const debug = (msg) => {
  winston.debug(msg, { tags: ClientUtil.getClientKey() });
};
