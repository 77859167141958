import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

class TopoSemMenu extends Component {
    _goHome = () => {
        this.props.history.push('/');
    }

    render() {
        return (
            <div className="header header2 header-fixed header-logo-center">
                <div className="header-title">
                    <img src={require('../resources/imgs/logo-zanzar-green.png')} className="logo-topo" alt="" onClick={ () => this._goHome() } />
                </div>
                <button className="header-icon header-icon-1 back-button" onClick={ () => window.history.go(-1) }>
                    <i className="fas fa-chevron-left"></i>
                </button>
            </div>
        );
    }
}

export default withRouter(TopoSemMenu);
