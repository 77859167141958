import React, { Component } from 'react';

export default class Cabecalho extends Component {
    render() {
        return (

            <div className="header-clear">
                <div className="space-8"></div>    
                <div className="title title-32">REGISTRO DE</div>
                <div className="space-16"></div>  
                <div className="title title-32">PROBLEMA</div>

                <div className="space-28"></div>

                <img src={require('../../../resources/imgs/svg/noun-wave.svg')}  className="noun-wave" alt="" />
            </div>
        );
    }
}