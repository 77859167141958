import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

class Cabecalho extends Component {
    render() {
        return (
            <div className="header-clear">
                <div className="space-8"></div>    
                <div className="title title-32">Equipamentos</div>

                <div className="space-28"></div>

                <img src={require('../../../resources/imgs/svg/noun-wave-green.svg')}  className="noun-wave" alt="" />
            </div>
        );
    }
}

export default withRouter(Cabecalho);
