import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Modal from 'react-awesome-modal';

import * as EquipamentoActions from '../../../actions/EquipamentoActions';

class Body extends Component {
    constructor(props) {
        super(props);

        this.state = {
            error: '',
			modalErrorShow: false,
            equipamentosLote: null
        };
    }

    componentDidMount = async () => {
        EquipamentoActions.obterEquipamentosLote(localStorage.getItem('cd_lote'))
            .then(equipamentosLote => {
                let equipamentosLoteCheck = null;

                if (equipamentosLote && equipamentosLote.length > 0) {
                    equipamentosLoteCheck = equipamentosLote.map((equipamento) => {
                        return {
                            cd_equipamento: equipamento.cd_equipamento,
                            cd_player: equipamento.cd_player,
                            isChecked: false
                        }
                    });
                }

                this.setState({ equipamentosLote: equipamentosLoteCheck });
            });
    }

    setModalErrorStatus = (show) => {
        this.setState({ modalErrorShow: show });
    }

    confirmarRecebimento = () => {
        let equipamentosLote = this.state.equipamentosLote;
        let equipamentosSelecionados = [];

        equipamentosLote.forEach(equipamento => {
            if (equipamento.isChecked) {
                equipamentosSelecionados.push(equipamento.cd_equipamento);
            }
        });

        if (equipamentosSelecionados.length > 0) {
            EquipamentoActions.confirmarRecebimentoEquipamentos(localStorage.getItem('cd_lote'), equipamentosSelecionados);

            window.location = '/receber-equipamentos';
        } else {
            this.setModalErrorStatus(true);
        }
    }

    handleAllChecked = (event) => {
        let equipamentosLote = this.state.equipamentosLote;

        equipamentosLote.forEach(equipamento => equipamento.isChecked = event.target.checked);

        this.setState({ equipamentosLote: equipamentosLote });
    }

    handleClick = (event) => {
        let equipamentosLote = this.state.equipamentosLote

        equipamentosLote.forEach(equipamento => {
            if (equipamento.cd_equipamento.toString() === event.target.value.toString())
                equipamento.isChecked = event.target.checked
        });

        this.setState({ equipamentosLote: equipamentosLote });
    }

    render() {
        return (
            <div className="clear space-25">
                <div className="cf-texto cf-sub-text margin-22">Assinale os equipamentos recebidos:</div>
                <div className="space-16"></div>
                {
                    this.state.equipamentosLote ? this.state.equipamentosLote.map((equipamento, k) => {
                        return <div key={k}>
                            <div className="space-16"></div>
                            <div className="row margin-10">
                                <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1">
                                    <div className="form-check-input">
                                        <label>
                                            <input type="checkbox"
                                                checked={ equipamento.isChecked }
                                                value={ equipamento.cd_equipamento }
                                                id={ "defaultCheck" + k }
                                                onChange={ this.handleClick } />
                                            <span className="cr"><i className="cr-icon glyphicon glyphicon-ok"></i></span>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                    <label className="form-check-label" htmlFor={ "defaultCheck" + k }>
                                        Código &nbsp;&nbsp; { equipamento.cd_player }
                                    </label>
                                </div>
                            </div>
                        </div>
                    }) : <div></div>
                }
                <div className="space-25"></div>
                <div className="row margin-10">
                    <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1">
                        <div className="form-check-input">
                            <label>
                                <input type="checkbox" value=""
                                    onClick={ this.handleAllChecked.bind(this) }
                                    id="checkTodos" />
                                <span className="cr"><i className="cr-icon glyphicon glyphicon-ok"></i></span>
                            </label>
                        </div>
                    </div>
                    <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                        <label className="form-check-label" htmlFor="checkTodos">
                            Selecionar todos
                        </label>
                    </div>
                </div>
                <div className="space-32"></div>

                <button className="cf-bt-main margin-32" onClick={ this.confirmarRecebimento }>CONFIRMAR RECEBIMENTO</button>
                <div className="space-71"></div>
                &nbsp;

                <Modal visible={ this.state.modalErrorShow } width="90%" effect="fadeInUp" onClickAway={ () => this.setModalErrorStatus(false) }>
                    <div style={{ padding: 10 }}>
                        <h4 className="uppercase">Erro</h4>
                        <hr />
                        <div className="space-16"></div>

                        <div className="div-center" style={{ color: 'red', padding: '6px' }}>
                            Selecione 1 ou mais equipamentos para confirmar o recebimento.
                        </div>
                        <div className="space-16"></div>

                        <div className="box-footer text-center">
                            <div className="btn-group">
                                <button className="btn btn-danger" onClick={ () => this.setModalErrorStatus(false) }>Fechar</button>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

export default withRouter(Body);
