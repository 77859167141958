import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import TopoSomenteLogo from '../../components/TopoSomenteLogo';
import MenuInferior from '../../components/MenuInferior';
import Cabecalho from './components/Cabecalho';
import Instalacoes from './components/Instalacoes';
import Equipamentos from './components/Equipamentos';

class Dashboard extends Component {
    constructor(props){
        super();

        this.iconeCenter = 3;
        this.iconeSelected = 3;
        this.motorista_logado = null;

        if (typeof props.match.params.iconeCenter !== 'undefined')
            this.passo = props.match.params.iconeCenter;

        if (typeof props.match.params.iconeSelected !== 'undefined')
            this.passo = props.match.params.iconeSelected;

        this.state = {
            dataEscolhida: null
        };    
    }

    alterarData = (dataEscolhida) => {
        this.setState({ dataEscolhida: dataEscolhida });
    }

    render() {
        return (
            <div className="page-content-normal">
                <TopoSomenteLogo/>
                <Cabecalho alterarDataParent={ this.alterarData }/>
                <Instalacoes dataEscolhida={ this.state.dataEscolhida } />
                <Equipamentos dataEscolhida={ this.state.dataEscolhida } />
                <MenuInferior iconeCenter={ this.iconeCenter } iconeSelected={ this.iconeSelected }/>
            </div>
        );
    }
}

export default withRouter(Dashboard);
