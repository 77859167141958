import axios from "axios";
import $ from "jquery";
import { obterCodigoPontoApoio } from "../actions/PontoApoioActions";
import auth from "../auth/initAuth";
import * as Logger from "../helpers/Logger";

const config = {
  headers: { Authorization: "Bearer " + localStorage.getItem("id_token") },
};

const urlAPI = process.env.REACT_APP_URL_API_BASE;

export const login = ({ email, senha, captcha, valorCaptcha }) => {
  axios
    .post(
      urlAPI + "/sistema-usuario/login-ponto-apoio",
      { email: email, password: senha, captcha, valorCaptcha },
      config
    )
    .then((response) => {
      if (response.data && response.data.isOk) {
        obterCodigoPontoApoio(email).then((ponto_apoio) => {
          localStorage.setItem("ponto_apoio", ponto_apoio);
          auth.login(email, senha, (err, authResult) => {
            if (err) {
              $(".alert").show();
              $(".loader-main").addClass("loader-inactive");
              console.log("Login error", err.message + "-" + err.description);

              localStorage.removeItem("ponto_apoio");
              return true;
            }
            if (authResult && authResult.idToken && authResult.accessToken) {
              this.setToken(authResult.accessToken, authResult.idToken);
              window.location = window.location.origin;
            }
          });
        });
      } else {
        $(".alert").show();
        $(".loader-main").addClass("loader-inactive");
      }
    })
    .catch((error) => {
      console.log(error.message);
      if (error.response && error.response.data) {
        // console.log(error.response.data);
        $(".alert").show();
        $(".loader-main").addClass("loader-inactive");
      }
      let errorMsg =
        error.response && error.response.data
          ? error.response.data.error
          : error.message;
      Logger.error("LOGIN MOTORISTA : ERRO : " + errorMsg);
      Logger.error("LOGIN MOTORISTA : ERRO : " + JSON.stringify(error));
    });
};

export const alterarSenha = (email, senha, page) => {
  return axios
    .post(
      urlAPI + "/sistema-usuario/alterar-senha",
      {
        email: email,
        senha: senha,
        connection: "Zanzar-PWA-PontoApoio",
      },
      config
    )
    .then((response) => {
      if (response.data.isOk) {
        page.setState({ show: true });
      } else {
        console.log("error.message", response.data.message);
        page.setState({ error: true, errorMsg: response.data.message });
      }
      $(".loader-main").addClass("loader-inactive");
    })
    .catch((error) => {
      console.log(error.message);
      if (error.response && error.response.data) {
        console.log(error.response.data);
        page.setState({ error: true });
      }
      $(".loader-main").addClass("loader-inactive");
    });
};

export const recadastrarSenha = (email, senha, token, page) => {
  return axios
    .post(
      urlAPI + "/sistema-usuario/atualizar-senha",
      {
        email: email,
        senha: senha,
        token: token,
        connection: "Zanzar-PWA-PontoApoio",
      },
      config
    )
    .then((response) => {
      if (response.data.isOk) {
        page.setState({ show: true });
      } else {
        page.setState({ error: true });
      }
      $(".loader-main").addClass("loader-inactive");
    })
    .catch((error) => {
      console.log(error.message);
      if (error.response && error.response.data) {
        console.log(error.response.data);
        page.setState({ error: true });
      }
      $(".loader-main").addClass("loader-inactive");
    });
};

export const recuperarSenha = (email, currentUrl, page) => {
  axios
    .post(urlAPI + "/sistema-usuario/recuperar-senha", {
      email: email,
      url: currentUrl,
      sistema: 3,
    })
    .then((response) => {
      if (response.data.isOk) {
        page.setState({ show: true });
      } else {
        page.setState({ error: true });
      }
    })
    .catch((error) => {
      console.log(error.message);
      if (error.response && error.response.data) {
        console.log(error.response.data);
        page.setState({ error: true });
      }
    });
};
