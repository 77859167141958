import React, { Component } from 'react';
import Topo from './components/Topo';
// import MenuInferior from '../../components/MenuInferior';
import Cabecalho from './components/Cabecalho';
import Body from './components/Body';
// import Equipamentos from './components/Equipamentos';


export default class RegistroProblema extends Component {
    constructor(props){
        super();

        this.iconeCenter = 3;
        this.iconeSelected = 3;
        this.motorista_logado = null;

        if (typeof props.match.params.iconeCenter !== 'undefined')
            this.passo = props.match.params.iconeCenter;

        if (typeof props.match.params.iconeSelected !== 'undefined')
            this.passo = props.match.params.iconeSelected;
    }

    componentWillMount = async () => {
        // let motorista_logado = localStorage.getItem('ponto_apoio_logado');

        // if (typeof motorista_logado !== 'undefined' && motorista_logado !== null)
        //     this.motorista_logado = JSON.parse(motorista_logado);
        // else
        //     window.location = '/login';
    }

    render() {
        return (
            <div className="page-content-normal">
                <Topo/>
                <Cabecalho/>
                <Body/>
                {/* <MenuInferior iconeCenter={ this.iconeCenter } iconeSelected={ this.iconeSelected }/> */}
            </div>
        );
    }
}