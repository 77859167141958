import axios from "axios";

const config = {
  headers: { Authorization: "Bearer " + localStorage.getItem("id_token") },
};

const urlAPI = process.env.REACT_APP_URL_API_BASE;

export const obterCodigoPontoApoio = async (email) => {
  let response = await axios.get(
    urlAPI + "/ponto-apoio-usuario/usuario/" + email,
    config
  );
  return response.data.ponto_apoio;
};

export const obterDadosPontoApoio = async (email) => {
  let response = await axios.get(
    urlAPI + "/ponto-apoio-usuario/dados/" + email,
    config
  );
  return response.data[0];
};
