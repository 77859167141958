import React, { Component } from 'react';
import TopoSomenteLogo from '../../components/TopoSomenteLogo';
import MenuInferior from '../../components/MenuInferior';
import MeuPerfilComponente from './components/MeuPerfil';

export default class MeuPerfil extends Component {
    constructor(props){
        super();

        this.iconeCenter = 4;
        this.iconeSelected = 4;
        this.motorista_logado = null;

        if (typeof props.match.params.iconeCenter !== 'undefined')
            this.passo = props.match.params.iconeCenter;

        if (typeof props.match.params.iconeSelected !== 'undefined')
            this.passo = props.match.params.iconeSelected;
    }

    render() {
        return (
            <div>
                <TopoSomenteLogo/>
                <MeuPerfilComponente/>
                <MenuInferior iconeCenter={ this.iconeCenter } iconeSelected={ this.iconeSelected }/>
            </div>
        );
    }
}