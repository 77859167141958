import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Formatters from '../../../helpers/Formatters';
import { obterDadosPontoApoio } from '../../../actions/PontoApoioActions';
import auth from '../../../auth/initAuth'

export default class Termo extends Component {
    constructor(){
        super();

        this.state = {
            nome: '',
            cpf: '',
            email: '',
            nome_ponto_apoio: '',
            codigo_ponto_apoio: '',
            cnpj_ponto_apoio: ''
        };
    }

    componentWillMount = async () => {
        let email = auth.getProfile().email;
        let dadosPontoApoio = await obterDadosPontoApoio(email)

        this.setState({nome: dadosPontoApoio.usuario.nome,
            cpf: Formatters.formataCPF(dadosPontoApoio.usuario.cpf),
            email: dadosPontoApoio.usuario.email,
            nome_ponto_apoio: dadosPontoApoio.ponto_apoio.descricao,
            codigo_ponto_apoio: dadosPontoApoio.ponto_apoio.cd_ponto_apoio,
            cnpj_ponto_apoio: Formatters.formataCNPJ(dadosPontoApoio.ponto_apoio.cnpj)
        });
    }

    _logout = () => {
        auth.logout();
    }

    render() {
        return (
            <div className="header-clear">
                <div className="space-8"></div>
                <div className="title title-32">Meu Perfil</div>

                <div className="space-23"></div>
                <img src={require('../../../resources/imgs/svg/noun-wave-green.svg')} className="noun-wave" alt="" />

                <div className="margin-25 space-17">
                    <div className="cf-title">Nome</div>
                    <div className="cf-sub-title">{ this.state.nome }</div>
                </div>

                <div className="margin-25 space-17">
                    <div className="cf-title">CPF</div>
                    <div className="cf-sub-title">{ this.state.cpf }</div>
                </div>

                <div className="margin-25 space-17">
                    <div className="cf-title">E-mail</div>
                    <div className="cf-sub-title">{ this.state.email }</div>
                </div>

                <div className="margin-25 space-17">
                    <div className="cf-title">Ponto de apoio</div>
                    <div className="cf-sub-title">{ this.state.codigo_ponto_apoio } - { this.state.nome_ponto_apoio }</div>
                </div>

                <div className="margin-25 space-17">
                    <div className="cf-title">CNPJ do ponto de apoio</div>
                    <div className="cf-sub-title">{ this.state.cnpj_ponto_apoio }</div>
                </div>
                <div className="row clear space-17">
                    <Link to={'/alterar-senha'}>
                        <button className="cf-bt-mini margin-30">Alterar Senha</button>
                    </Link>
                </div>
                <div className="row clear space-17">
                    <button className="bt-red-mini margin-30" onClick={() => this._logout()}>Sair</button>
                </div>
                <div className="space-71"></div>
            </div>
        );
    }
}
