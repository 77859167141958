import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import * as EquipamentoActions from '../../../actions/EquipamentoActions';

const formatDate = require('date-format');

class Body extends Component {
    constructor(props) {
        super(props);

        this.state = {
            error: '',
            data_ultimo_lote: null,
            estoque: null,
            proximosLotes: []
        };
    }

    componentDidMount = async () => {
        $('.loader-main').removeClass('loader-inactive');
        
        let dataUltimoLote = '-';
        let estoque = null;

        let ultimoLote = await EquipamentoActions.obterUltimoLoteRecebimento(localStorage.getItem('ponto_apoio'));
        if (ultimoLote && ultimoLote.cd_lote) {
            estoque = await EquipamentoActions.obterEquipamentosLote(ultimoLote.cd_lote);
            dataUltimoLote = ultimoLote.dt_distribuicao ? formatDate('dd/MM', new Date(ultimoLote.dt_distribuicao)) : '-';
        }

        let proximosLotes = await EquipamentoActions.obterProximosLotesRecebimento(localStorage.getItem('ponto_apoio'));
        if (proximosLotes && proximosLotes.length > 0) {
            let proximoLote = proximosLotes[0];

            localStorage.setItem('cd_lote', proximoLote.cd_lote);
        }

        this.setState({
            data_ultimo_lote: dataUltimoLote,
            estoque: estoque,
            proximosLotes: proximosLotes
        });

        $('.loader-main').addClass('loader-inactive');
    }

    render() {
        return (
            <div className="clear space-25">
                <div className="cf-texto cf-sub-text margin-22">Última data de recebimento:</div>
                <div className="space-16"></div>
                <div className="title-36 cf-bold margin-22">
                    { this.state.data_ultimo_lote }
                </div>
                <div className="space-16"></div>
                <div className="cf-texto cf-sub-title margin-22">
                    { this.state.estoque ? this.state.estoque.length : 0 } TVs Zanzar
                </div>

                <div className="space-39"></div>
                {
                    this.state.proximosLotes.map((lote, k) => {
                        return <div key={ k }>
                            <div className="rectangle">
                                <div className="cf-texto cf-sub-text space-16">Entrega do próximo lote:</div>
                                <div className="space-16"></div>
                                <div className="title-36 cf-bold ">
                                    { lote.status_desc }
                                </div>
                                <div className="space-16"></div>
                                <div className="cf-texto cf-sub-text space-16">
                                    Dia: { lote.dt_distribuicao ? formatDate('dd/MM às hh:mm', new Date(lote.dt_distribuicao)) : '-' }
                                </div>

                                <Link to={'/confirmar-equipamentos'} style={ (lote.idc_status !== 2 ? { display: 'none' } : {}) }>
                                    <button className="cf-bt space-16">Confirmar recebimento</button>
                                </Link>

                                <div className="space-16"></div>
                            </div>
                            <div className="space-16"></div>
                        </div>
                    })
                }
                <div className="space-50"></div>
                &nbsp;
            </div>
        );
    }
}

export default withRouter(Body);
