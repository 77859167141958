import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import TopoSomenteLogo from '../../components/TopoSomenteLogo';
import MenuInferior from '../../components/MenuInferior';
import Cabecalho from './components/Cabecalho';
import Body from './components/Body';
import auth from '../../auth/initAuth'

class ConfirmarEquipamentos extends Component {
    constructor(props) {
        super();

        this.iconeCenter = 1;
        this.iconeSelected = 1;

        if (typeof props.match.params.iconeCenter !== 'undefined')
            this.passo = props.match.params.iconeCenter;

        if (typeof props.match.params.iconeSelected !== 'undefined')
            this.passo = props.match.params.iconeSelected;
    }

    componentDidMount = async () => {
        if (!auth.loggedIn())
            auth.logout();
    }

    render() {
        return (
            <div className="page-content-normal">
                <TopoSomenteLogo />
                <Cabecalho />
                <Body />
                <MenuInferior iconeCenter={this.iconeCenter} iconeSelected={this.iconeSelected} />
            </div>
        );
    }
}

export default withRouter(ConfirmarEquipamentos);
