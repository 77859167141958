import React, { Component } from 'react';
import TopoSemMenuLogin from '../../components/TopoSemMenuLogin';
import FormRecadastrar from './components/FormRecadastrar';

export default class Login extends Component {
    render() {
        return (
            <div>
                <TopoSemMenuLogin/>
                <FormRecadastrar location={this.props.location}/>
            </div>
        );
    }
}