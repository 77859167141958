import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import ErrorPage from './scenes/error-page';
import Login from './scenes/login';
import EsqueciSenha from './scenes/esqueci-senha';
import RecadastrarSenha from './scenes/recadastrar-senha';
import AlterarSenha from './scenes/alterar-senha';
import MeuPerfil from './scenes/meu-perfil';
import Dashboard from './scenes/dashboard';
import DashboardHistorico from './scenes/dashboard-historico';
import ReceberEquipamentos from './scenes/receber-equipamentos';
import ConfirmarEquipamentos from './scenes/confirmar-equipamentos';
import RegistroProblema from './scenes/registro-problema';
import Agendamentos from './scenes/agendamentos';
import AgendamentoDetalhe from './scenes/agendamento-detalhe';
import Callback from './scenes/callback';
import auth from './auth/initAuth';

import './App.css';

export default class App extends React.Component {
    componentDidMount() {
        if (!['/login','/callback','/esqueci-senha','/recadastrar-senha'].includes(window.location.pathname)) {
			if (!auth.loggedIn())
				auth.logout();
		}
	}

	render() {
    	return (
			<BrowserRouter>
				<Switch>
					<Route exact path="/" component={Dashboard} />
					<Route path="/login" component={Login} />
					<Route path="/esqueci-senha" component={EsqueciSenha} />
					<Route path="/recadastrar-senha" component={RecadastrarSenha} />
					<Route path="/alterar-senha" component={AlterarSenha} />
					<Route path="/meu-perfil" component={MeuPerfil} />
					<Route path="/home" component={Dashboard} />
					<Route path="/dashboard" component={Dashboard} />
					<Route path="/dashboard-historico" component={DashboardHistorico} />
					<Route path="/receber-equipamentos" component={ReceberEquipamentos} />
					<Route path="/confirmar-equipamentos" component={ConfirmarEquipamentos} />
					<Route path="/registro-problema" component={RegistroProblema} />
					<Route path="/agendamentos" component={Agendamentos} />
					<Route path="/agendamento-detalhe" component={AgendamentoDetalhe} />
					<Route path="/callback" component={Callback} />
					<Route component={ErrorPage} />
				</Switch>
			</BrowserRouter>
    	);
  	}
}
