import React, { Component } from 'react';

export default class Topo extends Component {
    render() {
        return (
            <div className="header header2 header-fixed header-logo-center">
                <div className="header-title">
                <img src={require('../../../resources/imgs/logo-zanzar.png')} className="logo-topo" alt="" />
                </div>
                <a href="/dashboard" className="header-icon header-icon-1" data-menu="menu-sidebar">
                     <img src={require('../../../resources/imgs/svg/arrow-menu.svg')} className="arrow-menu" alt="" />
                 </a>
            </div>
            // <div className="header header-cf header-logo-center">
            //     <div className="header-title">
            //         <img src={require('../../../resources/imgs/logo-zanzar.png')} className="logo-topo" alt="" />
            //     </div>
            //     <a href="#/" className="header-icon header-icon-1" data-menu="menu-sidebar">
            //         <img src={require('../../../resources/imgs/svg/arrow-menu.svg')} className="arrow-menu" alt="" />
            //     </a>
            // </div>
        );
    }
}