import React, { Component } from 'react';
import MaskedInput from 'react-text-mask';
import Modal from 'react-awesome-modal';
import $ from 'jquery';

import * as AgendamentoActions from '../../../actions/AgendamentoActions';
import * as ServicoMotivoActions from '../../../actions/ServicoMotivoActions';

const formatDate = require('date-format');

export default class AgendamentoDetalhe extends Component {
	constructor(props){
        super(props);

        this.state = {
			agendamento: null,
			modalShow: false,
			motivos_nao_execucao: [],
			motivoEscolhido: null
        };
	}

	componentDidMount = async () => {       
		let zanzar_agendamento_detalhe = JSON.parse(localStorage.getItem('zanzar_agendamento_detalhe'));

        if (typeof zanzar_agendamento_detalhe !== 'undefined' && zanzar_agendamento_detalhe !== null) {
			this.setState({ agendamento: zanzar_agendamento_detalhe });
		} else {
			window.location = '/agendamentos';
		}
	}

    setModalStatus = (show) => {
		let dados = this.state;
		dados.modalShow = show;
        this.setState(dados);
    }

	_confirmarInstalacao = () => {
		this._handleError(null);
		$('.loader-main').removeClass('loader-inactive');

		let formOk = true;

		if (!this.state.cd_tv_zanzar || this.state.cd_tv_zanzar.trim() === '') {
            this._handleError('Informe o código de sua TV Zanzar');
			$('.loader-main').addClass('loader-inactive');
            formOk = false;
		} else {
            let regex = /[0-9].[0-9][0-9][0-9][0-9][0-9]/gim;

            if (!regex.test(this.state.cd_tv_zanzar)) {
				this._handleError('Código informado inválido');
				$('.loader-main').addClass('loader-inactive');
				formOk = false;
			}
		}

		if (formOk) {
			AgendamentoActions.confirmarInstalacao(this.state.agendamento.cd_agendamento, this.state.cd_tv_zanzar, localStorage.getItem('ponto_apoio'))
				.then(res => {
					$('.loader-main').addClass('loader-inactive');

					window.location = '/agendamentos';
				})
				.catch((error) => {
					this._handleError(error.response.data.message);
					$('.loader-main').addClass('loader-inactive');
				});
		}
	}

	_comunicarNaoInstalacao = () => {
		$('.loader-main').removeClass('loader-inactive');

		ServicoMotivoActions.listarMotivos()
			.then(res => {
				$('.loader-main').addClass('loader-inactive');

				let dados = this.state;
				dados.motivos_nao_execucao = res;

				this.setState(dados);
				this.setModalStatus(true);
			})
			.catch((error) => {
				this._handleErrorNI(error.response.data.message);
				$('.loader-main').addClass('loader-inactive');
			});
	}

	_confirmarComunicarNaoInstalacao = () => {
        if (this.state.motivoEscolhido === null) {
			this._handleErrorNI('Selecione uma motivo de não execução.');
		} else {
			$('.loader-main').removeClass('loader-inactive');

			AgendamentoActions.comunicarNaoInstalacao(this.state.agendamento.cd_agendamento, this.state.motivoEscolhido, this.state.observacao)
				.then(res => {
					$('.loader-main').addClass('loader-inactive');

					window.location = '/agendamentos';
				})
				.catch((error) => {
					this._handleErrorNI(error.response.data.message);
					$('.loader-main').addClass('loader-inactive');
				});
		}
	}

    _selecionar = (cd_motivo) => {
		let dados = this.state;
		dados.motivoEscolhido = cd_motivo;

		this.setState(dados);
    }

    _handleErrorNI = (error) => {
        let dados = this.state;
        dados.error_comunicar_ni = error;

        this.setState(dados);
    }

    _handleError = (error) => {
        let dados = this.state;
        dados.error = error;

        this.setState(dados);
    }

    _updateField = async (event) => {
        let campo = event.target.id;
        let valor = event.target.value;
        let dados = this.state;

        dados[campo] = valor;
        await this.setState(dados);
    }

    _maskDelete = (e) => {
        const cursorPos = Number(e.target.selectionStart);
        const keyCode = Number(e.keyCode);

        if (keyCode === 8) {
            e.preventDefault();
            e.stopPropagation();

            e.target.value = e.target.value.substring(0, cursorPos - 1);
            e.target.setSelectionRange(cursorPos, cursorPos);
        }
    }

    render() {
        return (
            <div className="page-content-normal header-clear">
				<div className="space-16"></div>
				<div className="title title-32 margin-22">{ this.state.agendamento ? this.state.agendamento.tipo_servico_descricao : '' }</div>

				<div className="space-23"></div>
				<img src={require('../../../resources/imgs/svg/noun-wave-green.svg')} className="noun-wave" alt="" />

				<div className="space-21"></div>
				<div className="cf-texto cf-title margin-22">Resumo</div>
				
				<div className="space-16"></div>
				<div className="rectangle">   
					<div className="space-16 margin-22 cf-texto title-16 title-bold title-gray">Dia da instalação:</div>
					<div className="space-8 margin-30 cf-texto title-16 title-bold title-gray">{ this.state.agendamento ? formatDate('dd/MM', new Date(this.state.agendamento.dt_agendada)) : '' } </div>
					<div className="space-16 margin-22 cf-texto title-16 title-bold title-gray">Motorista:</div>
					<div className="space-8 margin-30 cf-texto title-16 title-bold title-gray">
						{ this.state.agendamento ? this.state.agendamento.motorista_nome : '' }
						<div className="space-8"></div>
						{ this.state.agendamento ? this.state.agendamento.motorista_veiculo.fabricante + ' / ' + this.state.agendamento.motorista_veiculo.modelo + ' / ' + this.state.agendamento.motorista_veiculo.ano : '' }
					</div>
					<div className="space-7">&nbsp;</div>
				</div>

                <div className="div-center" style={ this.state.error ? { color: 'red', padding: '6px' } : { display: 'none' } }>
                    { this.state.error }
                </div>

				<div className="space-16"></div>
				<div className="div-center">
					<label className="input-label-center">Código TV Zanzar</label>
                    <MaskedInput
                        mask={ [/\d/,'.',/\d/,/\d/,/\d/,/\d/,/\d/] }
                        className="box-input-only input margin-25 input-cd-tv-zanzar"
                        guide={ false }
                        id="cd_tv_zanzar"
                        placeholder="0.00000"
                        onChange={ e => { this._updateField(e) } }
                        onKeyDown= { e => { this._maskDelete(e) } } />
                </div>

				<div className="space-8"></div>
				<div className="div-center">
					<button className="cf-bt-main margin-30 uppercase" onClick={() => this._confirmarInstalacao()}>CONFIRMAR { this.state.agendamento ? this.state.agendamento.tipo_servico_descricao : '' }</button>
				</div>

				<div className="space-16"></div>
				<div className="div-body text-center">
					<button className="bt-white uppercase" style={{ border: 'none' }} onClick={() => this._comunicarNaoInstalacao()}>COMUNICAR NÃO { this.state.agendamento ? this.state.agendamento.tipo_servico_descricao : '' }</button>
				</div>
				<div className="space-71"></div>

				<Modal visible={ this.state.modalShow } width="90%" effect="fadeInUp" onClickAway={ () => this.setModalStatus(false) }>
                    <div style={{ padding: 10 }}>
                        <h4 className="uppercase">COMUNICAR NÃO { this.state.agendamento ? this.state.agendamento.tipo_servico_descricao : '' }</h4>
						<img src={require('../../../resources/imgs/svg/noun-wave-green.svg')} alt="" />
						<div className="space-16"></div>

                        <span>Selecione o motivo da não execução do serviço.</span>
						<div className="space-8"></div>
						<div className="row margin-22">
							{
								this.state.motivos_nao_execucao ? this.state.motivos_nao_execucao.map((item, k) => {
									return <div key={ k }
												onClick={() => this._selecionar(item.cd_motivo_servico)}
												className={ 'col-lg-12 col-md-12 col-sm-12 col-xs-12 box-select-option' + (this.state.motivoEscolhido === item.cd_motivo_servico ? ' box-date-selected' : '') }>
												{ item.descricao }
											</div>
								}) : ''
							}
						</div>

						<div className="div-center">
							<textarea className="box-input-only input-simples margin-10" id="observacao" placeholder="Observações" onChange={ e => { this._updateField(e) } }></textarea>
						</div>

						<div className="div-center" style={ this.state.error_comunicar_ni ? { color: 'red', padding: '6px' } : { display: 'none' } }>
							{ this.state.error_comunicar_ni }
						</div>

						<div className="box-footer text-center">
							<div className="btn-group">
								<button className="btn btn-danger" onClick={ () => this.setModalStatus(false) }>Cancelar</button>
								<button className="btn btn-success" onClick={ () => this._confirmarComunicarNaoInstalacao() }>Confirmar</button>
							</div>
						</div>
                    </div>
                </Modal>
			</div>
        );
    }
}
