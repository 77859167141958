import React, { Component } from 'react';
import { obterServicosRealizados } from '../../../actions/AgendamentoActions';
const mask = require('react-text-mask');
const formatDate = require('date-format');

export default class ServicosRalizados extends Component {
    constructor(props){
        super(props);

        this.state = {
            error: '',
            dataEscolhida: null,
            agendamentos: [],
            cd_ponto_apoio: null
        };
    }

    componentWillMount = async () => {
        let data = formatDate('ddMMyyyy', this.state.dataEscolhida);

        obterServicosRealizados(localStorage.getItem('ponto_apoio'), data)
            .then(agendamentos => {
                this.setState({ agendamentos: agendamentos });
            })
    }

    componentWillReceiveProps = (props) => {
        let data = formatDate('ddMMyyyy', props.dataEscolhida);

        obterServicosRealizados(localStorage.getItem('ponto_apoio'), data)
            .then(agendamentos => {
                this.setState({ agendamentos: agendamentos });
            })
    }

    render() {
        return (
            <div>
                <div className="cf-texto cf-title margin-22">
                    { this.state.agendamentos ? this.state.agendamentos.length : 0 }  <span className="title-16">Instalações realizadas</span>
                </div>
                {
                    this.state.agendamentos ? this.state.agendamentos.map((agendamento, k) => {
                        return  <div key={k}>
                                    <div className="space-16"></div>
                                    <div className="box-radius-green margin-22">
                                        <div className="margin-20 cf-texto title-16 title-bold title-gray">Motorista</div>
                                        <div className="space-8"></div>
                                        <div className="margin-30 cf-texto title-16 title-bold title-gray">
                                            {agendamento.motorista_nome}
                                            <div className="space-8"></div>
                                            {
                                                mask.conformToMask(agendamento.motorista_tel_celular, 
                                                    ['(', /[1-9]/, /\d/,  ')', ' ',/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]).conformedValue
                                            }
                                        </div>
                                    </div>
                                </div>
                    }) : <div></div>
                }
            </div>
        );
    }
}
