import React, { Component } from 'react';
import $ from 'jquery';
import { alterarSenha } from '../../../actions/UsuarioActions';
import auth from '../../../auth/initAuth'

export default class FormAlterar extends Component {
    constructor(props){
        super();

        this.state = {
            email: '',
            senha: '',
            repetirSenha: '',
            show: false,
            error: false,
            errorMsg: ''
        };

        this.camposObrigatorios = ['repetirSenha', 'senha'];
    }

    componentWillMount = async () => {
        let email = auth.getProfile().email;

        this.setState({ email: email });
    }

    componentDidMount() {
        $('.box-input').click(function() {
            var label = $(this).find('.input-label');
            var input = $('#'+ label.attr('for'));

            label.removeClass('input-label');
            label.addClass('input-label-sm');
            input.removeClass('input-hide');

            $('.box-input-sel').removeClass('box-input-sel');
            $(this).addClass('box-input-sel');
    
            input.trigger('click');
        });
    }

    _troca = () => {
        let formOk = true;

        this.setState({ show: false, error: false, errorMsg: ''});

        this.camposObrigatorios.forEach(function(item) {

            $('#'+ item).parent().removeClass('box-input-required');
            if ($('#'+ item).val() === '') {
                $('#'+ item).parent().addClass('box-input-required');

                formOk = false;
            }
        });

        if ( this.state.senha === '' && this.state.repetirSenha === '') {
            $('#senha').parent().addClass('box-input-required');
            $('#repetirSenha').parent().addClass('box-input-required');
            formOk = false;
        }

        if ( this.state.senha !== this.state.repetirSenha) {
            $('#repetirSenha').parent().addClass('box-input-required');
            formOk = false;
        }

        if (formOk) {
            $('.loader-main').removeClass('loader-inactive');
            let { email, senha } = this.state;
            alterarSenha(email, senha, this);
        }
    }

    _updateField = async function(event) {
        let dados = this.state;
        dados[event.target.id] = event.target.value;
        await this.setState(dados);
    }

    render() {
        return (
            <div className="page-content-normal header-clear">
                <div className="title title-36">ALTERAR SENHA</div>
                <div className="space-32"></div>

                <div className="cf-texto div-body margin-30">Insira sua nova senha para <br/>{ this.state.email }</div>

                <div className="space-71"></div>

                <div style={ this.state.show ? { } : { display: 'none' } }>
                    <div className="alert alert-success div-div-center margin-25" >
                        Senha alterada com sucesso. 
                    </div>
                    <div className="space-26"></div>
                </div>

                <div style={ this.state.error ? { } : { display: 'none' } }>
                    <div className="alert alert-danger div-div-center margin-25" >
                        {this.state.errorMsg}
                    </div>
                    <div className="space-26"></div>
                </div>

                <div className="box-input margin-25">
                    <label className="input-label" htmlFor="senha">Senha</label>
                    <input type="password" id="senha" className="input input-hide" value={ this.state.senha } onChange={ e => { this._updateField(e) } } />
                </div>

                <div className="box-input margin-25">
                    <label className="input-label" htmlFor="repetirSenha">Repetir Senha</label>
                    <input type="password" id="repetirSenha" className="input input-hide" value={ this.state.repetirSenha } onChange={ e => { this._updateField(e) } } />
                </div>

                <div className="space-26"></div>
                <div className="div-center">
                    <button className="bt-login margin-30" onClick={() => this._troca()}>Alterar Senha</button>
                </div>
                <div className="space-26"></div>
            </div>
        );
    }
}