import React, { Component } from 'react';

export default class MenuInferior extends Component {
    constructor(props){
        super();

        this.iconeSelected = props.iconeSelected;
    }

    render() {
        let iconesClass = ['','icon-menu hcenter','icon-menu hcenter','icon-menu hcenter','icon-menu hcenter'];
        let icones = ['','','','', ''];

        icones[this.iconeSelected] = '-sel';
        iconesClass[this.iconeSelected] += ' icon-sel';

        return (
            <div className="navigation four-icons">
               <a href="/receber-equipamentos" className="nav-icon">
                    <div className={ iconesClass[1] }>
                        <img src={require('../resources/imgs/menu-ico-1'+ icones[1] +'.png')} className="center vcenter" heigth="25" alt="" />
                    </div>
                </a>
                <a href="/" className="nav-icon">
                    <div className={ iconesClass[2] }>
                        <img src={require('../resources/imgs/menu-ico-2'+ icones[2] +'.png')} className="center vcenter" heigth="25" alt="" />
                    </div>
                </a>
                <a href="/dashboard" className="nav-icon">
                    <div className={ iconesClass[3] }>
                        <img src={require('../resources/imgs/menu-ico-3' + icones[3] +'.png')} className="center vcenter" heigth="25" alt="" />
                    </div>
                </a>
                <a href="/meu-perfil" className="nav-icon">
                    <div className={ iconesClass[4] }><i className="far fa-user vcenter"></i></div>
                </a>
            </div>
        );
    }
}