import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

class TopoSemMenuLogin extends Component {
    _goBack = () => {
        this.props.history.push('/login');
    }

    _goHome = () => {
        this.props.history.push('/');
    }

    render() {
        return (
            <div className="header header2 header-fixed header-logo-center">
                <div className="header-title">
                    <img src={require('../resources/imgs/logo-zanzar-green.png')} className="logo-topo" alt="" onClick={ () => this._goHome() } />
                </div>
                {/* <button className="header-icon header-icon-1 back-button" onClick={ () => this._goBack() }>
                    <i className="fas fa-chevron-left"></i>
                </button> */}
                <a href="/#/" onClick={ () => this._goBack() } className="header-icon header-icon-1 back-button">
                    <i className="fas fa-chevron-left"></i>
                </a>
            </div>
        );
    }
}

export default withRouter(TopoSemMenuLogin);
