import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import $ from 'jquery';
import * as Logger from '../../../helpers/Logger';
import * as ClientUtil from '../../../helpers/ClientUtil';

import * as EquipamentoActions from '../../../actions/EquipamentoActions';

const formatDate = require('date-format');

class Equipamentos extends Component {
    constructor(props) {
        super(props);

        this.state = {
            error: '',
            dashboard: [],
            servicoes_pendentes: [],
            servicos_realizados: [],
            data_ultimo_lote: null,
            estoque: null,
            cd_ponto_apoio: null
        };
    }

    componentDidMount = async () => {
        $('.loader-main').removeClass('loader-inactive');

        let deviceinfo = await ClientUtil.getDeviceInfo();
        Logger.info('APOIO : DASHBOARD - Equipamentos : APARELHO : ' + deviceinfo);

        let cd_ponto_apoio = localStorage.getItem('ponto_apoio');

        Logger.info('APOIO : DASHBOARD - Equipamentos : cd_ponto_apoio : ' + cd_ponto_apoio);

        try {
            let estoque = await EquipamentoActions.obterEstoque(cd_ponto_apoio);
            let ultimoLote = await EquipamentoActions.obterUltimoLoteRecebimento(cd_ponto_apoio);

            Logger.info('APOIO : DASHBOARD - Equipamentos : estoque : ' + JSON.stringify(estoque));
            Logger.info('APOIO : DASHBOARD - Equipamentos : estoque : ' + (estoque ? estoque.length : 0));
            Logger.info('APOIO : DASHBOARD - Equipamentos : ultimoLote : ' + JSON.stringify(ultimoLote));

            let dataUltimoLote = ultimoLote.dt_distribuicao ? formatDate('dd/MM', new Date(ultimoLote.dt_distribuicao)) : '-';

            this.setState({ data_ultimo_lote: dataUltimoLote, estoque: estoque });

            $('.loader-main').addClass('loader-inactive');
        } catch (error) {
            Logger.error('APOIO : DASHBOARD - Equipamentos : ERRO 1 : ' + error.stack);
            Logger.error('APOIO : DASHBOARD - Equipamentos : ERRO 2 : ' + JSON.stringify(error));

            $('.loader-main').addClass('loader-inactive');
        }

        Logger.info('APOIO : DASHBOARD - Equipamentos : FIM');
    }

    render() {
        return (
            <div className="clear">
                <div className="rectangle">
                    <div className="cf-texto cf-title space-16">Equipamentos</div>
                    <div className="space-21"></div>

                    <Link to={'/receber-equipamentos'}>
                        <div className="row clear">
                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                <div className="cf-texto cf-sub-title margin-20">Estoque</div>
                                <div className="space-8"></div>
                                <div className="title title-32 text-destaque2">
                                    { this.state.estoque ? this.state.estoque.length : 0 }
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 Line-3">
                                <div className="cf-texto cf-sub-title margin-20  text-destaque3">Último recebimento</div>
                                <div className="space-8"></div>
                                <div className="title title-32 text-destaque2">
                                    { this.state.data_ultimo_lote }
                                </div>
                            </div>
                        </div>
                    </Link>

                    <div className="space-71">&nbsp;</div>
                </div>
            </div>
        );
    }
}

export default withRouter(Equipamentos);
