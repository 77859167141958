import React, { Component } from 'react';
import TopoSemMenu from '../../components/TopoSemMenu';
import MenuInferior from '../../components/MenuInferior';
import Agendamentos from './components/Agendamentos';

export default class ReceberEquipamentos extends Component {
    constructor(props){
        super();

        this.iconeSelected = 3;
    }

    render() {
        return (
            <div>
                <TopoSemMenu/>
                <Agendamentos/>
                <MenuInferior iconeSelected={ this.iconeSelected }/>
            </div>
        );
    }
}