import React, { Component } from 'react';
import $ from 'jquery';
import { validate as isEmail } from "email-validator";
import { recuperarSenha } from '../../../actions/UsuarioActions';

export default class FormEsqueciSenha extends Component {
    constructor(props){
        super();

        this.state = {
            email: '',
            show: false,
            error: false
        };

        this.camposObrigatorios = ['email', 'senha'];
    }

    componentDidMount() {
        $('.box-input').click(function() {
            var label = $(this).find('.input-label');
            var input = $('#'+ label.attr('for'));

            label.removeClass('input-label');
            label.addClass('input-label-sm');
            input.removeClass('input-hide');

            $('.box-input-sel').removeClass('box-input-sel');
            $(this).addClass('box-input-sel');

            input.trigger('click');
        });
    }

    _logar = () => {
        let formOk = true;

        this.setState({show: false, error: false});

        this.camposObrigatorios.forEach(function(item) {
            if ($('#'+ item).val() === ''){
                $('#'+ item).parent().addClass('box-input-required');

                formOk = false;
            }
        });

        if ($('#email').val() !== '' && !isEmail($('#email').val())){
            $('#email').parent().addClass('box-input-required');
            formOk = false;
        }

        if (formOk){
            let { email } = this.state;

            var currentUrl = window.location.origin
            recuperarSenha(email, currentUrl, this)
        }
    }

    _updateField = async function(event) {
        let dados = this.state;
        dados[event.target.id] = event.target.value;
        await this.setState(dados);
    }

    render() {
        return (
            <div className="page-content-normal header-clear">
                <div className="title title-36">ESQUECI SENHA</div>
                <div className="space-32"></div>

                <div className="cf-texto div-body margin-30">Informe seu email</div>

                <div className="space-71"></div>

                <div style={ this.state.show ? { } : { display: 'none' } }>
                    <div className="alert alert-success div-div-center margin-25" >
                        Envio efetuado com sucesso.
                    </div>
                    <div className="space-26"></div>
                </div>

                <div style={ this.state.error ? { } : { display: 'none' } }>
                    <div className="alert alert-danger div-div-center margin-25" >
                        Email não identificado.
                    </div>
                    <div className="space-26"></div>
                </div>

                <div className="alert alert-danger margin-25" style={{ padding: '6px', display: 'none' }}>
					Login ou senha inválidos.
                </div>

                <div className="box-input margin-25">
                    <label className="input-label" htmlFor="email">E-mail</label>
                    <input type="text" id="email" className="input input-hide" value={ this.state.email } onChange={ e => { this._updateField(e) } } />
                </div>

                <div className="space-26"></div>
                <div className="div-center">
                    <button className="bt-login margin-30" onClick={() => this._logar()}>ENVIAR</button>
                </div>
                <div className="space-26"></div>
            </div>
        );
    }
}