import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import TopoSemMenu from '../../components/TopoSemMenu';
import MenuInferior from '../../components/MenuInferior';
import Cabecalho from './components/Cabecalho';
import Historico from './components/Historico';

class DashboardHistorico extends Component {
    constructor(props) {
        super();

        this.iconeCenter = 3;
        this.iconeSelected = 3;
        this.motorista_logado = null;

        if (typeof props.match.params.iconeCenter !== 'undefined')
            this.passo = props.match.params.iconeCenter;

        if (typeof props.match.params.iconeSelected !== 'undefined')
            this.passo = props.match.params.iconeSelected;

        this.state = {
            dataEscolhida: null
        };
    }

    alterarData = (dataEscolhida) => {
        this.setState({ dataEscolhida: dataEscolhida });
    }

    render() {
        return (
            <div className="page-content-normal">
                <TopoSemMenu />
                <Cabecalho alterarDataParent={ this.alterarData } />
                <Historico dataEscolhida={ this.state.dataEscolhida } />
                <MenuInferior iconeCenter={ this.iconeCenter } iconeSelected={ this.iconeSelected } />
            </div>
        );
    }
}

export default withRouter(DashboardHistorico);
