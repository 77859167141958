import { validate as isEmail } from "email-validator";
import $ from "jquery";
import React, { Component } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { login } from "../../../actions/UsuarioActions";

export default class FormLogin extends Component {
  constructor(props) {
    super();

    this.state = {
      email: "",
      senha: "",
      hidden: true,
      captcha: false,
      valorCaptcha: "",
      codigoCapctha: process.env.REACT_APP_RECAPTCHA,
    };

    this.camposObrigatorios = ["email", "senha"];
    // this.toggleShow = this.toggleShow.bind(this);
  }

  recaptchaRef = React.createRef();

  onChange = async () => {
    const recaptchaValue = await this.recaptchaRef.current.getValue();
    this.setState({ captcha: true });
    this.setState({ valorCaptcha: recaptchaValue });
    console.log("recaptchaValue: " + recaptchaValue);
  };

  componentDidMount() {
    $(".box-input").click(function () {
      var label = $(this).find(".input-label");
      var input = $("#" + label.attr("for"));

      label.removeClass("input-label");
      label.addClass("input-label-sm");
      input.removeClass("input-hide");

      $(".box-input-sel").removeClass("box-input-sel");
      $(this).addClass("box-input-sel");

      input.trigger("click");
    });
  }

  _logar = () => {
    console.log(this.state.codigoCapctha);
    console.log(process.env);
    let formOk = true;
    $(".alertCamposObrigatorios").hide();
    /*this.camposObrigatorios.forEach(function (item) {
      if ($("#" + item).val() === "") {
        $("#" + item)
          .parent()
          .addClass("box-input-required");

        formOk = false;
      }
    });*/

    this.camposObrigatorios.forEach(function (item) {
      if ($("#" + item).val() === "") {
        $(".alertCamposObrigatorios").show();
        formOk = false;
      }
    });

    if ($("#email").val() !== "" && !isEmail($("#email").val())) {
      $(".alertCamposObrigatorios").show();
      formOk = false;
    }

    if (!this.state.captcha) {
      $(".alertCamposObrigatorios").show();
      formOk = false;
    }

    if (formOk) {
      let { email, senha, captcha, valorCaptcha } = this.state;

      $(".loader-main").removeClass("loader-inactive");

      login({ email, senha, captcha, valorCaptcha });
    }
  };

  _updateField = async (event) => {
    let dados = this.state;
    dados[event.target.id] = event.target.value;
    await this.setState(dados);
  };

  _toggleShowPassword = () => {
    this.setState({ hidden: !this.state.hidden });
  };

  render() {
    return (
      <div className="page-content-normal header-clear">
        <div className="title title-36">LOGIN</div>
        <div className="space-32"></div>

        <div className="cf-texto div-body margin-30">
          Insira seus dados para entrar
        </div>

        <div className="space-71"></div>

        <div
          className="alertCamposObrigatorios alert-danger margin-25"
          style={{ padding: "6px", display: "none" }}
        >
          Todos campos são obrigatórios.
        </div>

        <div
          className="alert alert-danger margin-25"
          style={{ padding: "6px", display: "none" }}
        >
          Login ou senha inválidos.
        </div>

        <div className="box-input margin-25">
          <label className="input-label" htmlFor="email">
            E-mail
          </label>
          <input
            type="text"
            id="email"
            className="input input-hide"
            value={this.state.email}
            onChange={(e) => {
              this._updateField(e);
            }}
          />
        </div>

        <div className="box-input margin-25">
          <label
            className="input-label"
            htmlFor="senha"
            style={{ float: "left", width: "auto" }}
          >
            Senha
          </label>
          <i
            className={
              "icon-hide-pass far " +
              (this.state.hidden ? "fa-eye" : "fa-eye-slash")
            }
            onClick={this._toggleShowPassword}
          ></i>
          <input
            type={this.state.hidden ? "password" : "text"}
            id="senha"
            className="input input-hide"
            style={{ width: "75%" }}
            value={this.state.senha}
            onChange={(e) => {
              this._updateField(e);
            }}
          />
        </div>

        <div className="box-input-only2 margin-25 space-12">
          <ReCAPTCHA
            id="captcha"
            ref={this.recaptchaRef}
            value={this.state.captcha}
            sitekey={this.state.codigoCapctha}
            onChange={(e) => {
              this.onChange(e);
            }}
          />
        </div>

        <div className="cf-texto div-body margin-27">
          <a href="/esqueci-senha" className="label-purple">
            ESQUECI MINHA SENHA &nbsp;
            <i className="fas fa-chevron-right icon-14"></i>
          </a>
        </div>

        <div className="space-26"></div>
        <div className="div-center">
          <button className="bt-login margin-30" onClick={() => this._logar()}>
            ENTRAR <i className="fas fa-chevron-right icon-14 float-right"></i>
          </button>
        </div>
        <div className="space-26"></div>
      </div>
    );
  }
}
