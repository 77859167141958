import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom'
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { verificarSeNecessitaAtualizarVersaoPwa } from './actions/VersionActions';

ReactDOM.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>
, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

verificarSeNecessitaAtualizarVersaoPwa()
    .then(necessitaAtualizarPwa => {
        if (necessitaAtualizarPwa)
            serviceWorker.unregister();

        serviceWorker.register();

        var deferredPrompt;

        //  Controlando o banner de instalacao 
        var divInstall = document.querySelector('#enable-banner-install');
        var btnBannerInstall = document.querySelector('#btn-install');
        var btnCloseInstall = document.querySelector('#btn-install-close');

        //evento disparado para instalar a aplicacao
        window.addEventListener('beforeinstallprompt', (e) => {
            e.preventDefault();
            deferredPrompt = e;
            divInstall.style.display = 'block';
        });

        window.addEventListener('appinstalled', (evt) => {
        //   app.logEvent('a2hs', 'installed');
        });

        btnBannerInstall.addEventListener('click', (e) => {
            divInstall.style.display = 'none';
            deferredPrompt.prompt();
            deferredPrompt.userChoice
                .then((choiceResult) => {
                    if (choiceResult.outcome === 'accepted') {
                        console.log('User accepted the A2HS prompt');
                    } else {
                        console.log('User dismissed the A2HS prompt');
                    }
                    deferredPrompt = null;
                });
        });

        btnCloseInstall.addEventListener('click', (e) => {
            divInstall.style.display = 'none'; 
        });
    });
