import React, { Component } from 'react';
import FormAlterar from './components/FormAlterar';
import TopoSemMenu from '../../components/TopoSemMenu';
import MenuInferior from '../../components/MenuInferior';

export default class AlterarSenha extends Component {

    constructor(props){
        super();

        this.iconeCenter = 4;
        this.iconeSelected = 4;
        this.motorista_logado = null;

        if (typeof props.match.params.iconeCenter !== 'undefined')
            this.passo = props.match.params.iconeCenter;

        if (typeof props.match.params.iconeSelected !== 'undefined')
            this.passo = props.match.params.iconeSelected;
    }

    render() {
        return (
            <div>
                <TopoSemMenu/>
                <FormAlterar />
                <MenuInferior iconeCenter={ this.iconeCenter } iconeSelected={ this.iconeSelected }/>
            </div>
        );
    }
}