import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class Body extends Component {

   
    render() {
        return (

            <div className="clear space-25">
               
               <div className="cf-texto cf-sub-text margin-22">Selecione o problema encontrado:</div>
                <div className="space-16"></div>
                
                {/* <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/>
                    <label class="form-check-label" for="defaultCheck1">
                        Default checkbox
                    </label>
                </div> */}
               
               <div className="cf-texto cf-sub-text margin-22">Confirme o código do equipamento:</div>
                <div className="space-16"></div>

                <Link to={'/dashboard-historico'}>
                       <button class="cf-bt-main center">REPORTAR PROBLEMA</button>
                </Link>

                &nbsp;

{/* 
               

                <div className="space-28"></div>
                {/* <div className="rectangle">
                    <div className="margin-20">
                        <div className="space-28"></div>
                        <div className="cf-texto cf-title space-16">Equipamentos</div>
                    </div>
                </div> */}
            </div>
           

            // <div className="header-clear cadastro">
            //     <div className="page-content">
            //         <div className="title title-32 text-left text-destaque">Dashboard</div>

            //         <div className="cf-texto cf-title title-20 text-greyish" style={{ width: '100%' }}>Maio</div>

            //         <img src={require('../../../resources/imgs/svg/noun-wave.svg')}  className="noun-wave" alt="" />

            //         <div className="cf-texto cf-title title-20" style={{ width: '100%' }}>Instalações</div>

            //         <div className="cf-texto cf-title title-18 text-greyish" style={{ width: '100%' }}>Realizadas</div>

            //         <div className="cf-texto cf-title title-32 text-greyish" style={{ width: '100%' }}>32</div>

            //        

            //         {/* <div className="header-title">
            //             <img src={require('../../../resources/imgs/logo-zanzar-white.png')} className="logo-topo" alt="" />
            //         </div> */}
            //         {/* <a href="#/" className="header-icon header-icon-1" data-menu="menu-sidebar">
            //             <img src={require('../../../resources/imgs/svg/arrow-menu.svg')} className="arrow-menu" alt="" />
            //         </a> */}
            //     </div>
            
            //     <div className="rectangle">
                    
            //     </div>
            // </div>
        );
    }
}